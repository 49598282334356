import { useEffect, useState } from "react";

export default function CampaignDonorsBlock({ campaign }) {
  const [donationsDetails, setDonationsDetails] = useState([]);

  useEffect(() => {
    if (campaign?.donations_details) {
      // Filters for only completed donations
      const filteredDonations = campaign.donations_details.filter(
        (donation) => donation.status === "completed"
      );
      setDonationsDetails(filteredDonations);
    }
  }, [campaign]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
    return `${month}/${day}/${year}`;
  };

  return (
    <div className="flex flex-col w-full lg:mx-20">
      {/* Updates Box */}
      <div className="border rounded-2xl mb-12 shadow-lg p-4 w-full mx-auto">
        <h1 className="text-orange font-bold text-center mb-4">Updates</h1>
        <div className="space-y-4 mx-8">
          {campaign?.update_info && campaign.update_info.length > 0 ? (
            campaign?.update_info.map((update, idx) => (
              <div key={idx} className="border-b border-gray-200 py-2">
                {update.update_content}
              </div>
            ))
          ) : (
            <div className="text-center my-4 text-gray-500">No updates</div>
          )}
        </div>
      </div>

      {/* Recent Contributors Box */}
      <div className="border rounded-2xl shadow-lg p-4 w-full mx-auto">
        <h1 className="font-bold text-lg text-orange my-4">Recent Contributors</h1>
        {donationsDetails.length > 0 ? (
          donationsDetails.map((donation) => (
            <div
              key={donation.pid}
              className="flex mx-8 justify-between my-4 items-center py-2"
            >
              <div>
                <p className="font-semibold">
                  {donation.donor_first_name} contributed &nbsp;
                  <span className="text-blue font-bold">${donation.amount.toLocaleString()}</span>
                </p>
                
              </div>
              <p className="text-sm text-gray-500">{formatDate(donation.donation_date)}</p>
            </div>
          ))
        ) : (
          <div className="text-center my-4 text-gray-500">No contributors yet</div>
        )}
      </div>
    </div>
  );
}
