import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faMagnifyingGlass,
  faStar,
  faCat,
  faDog,
  faHorse,
  faOtter,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getPaginatedCampaigns } from "../../../api/Campaigns";
import useWindowDimensions from "../../../hooks/useWindowDimensions";
import { urlifyName } from "../../shared/Utils/PageUrl";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { extractTextContent } from "../../shared/Utils/ExtractText";
import { cardTitleExcerpt } from "../../shared/Utils/CardTitleExcerpt";
import CampaignCard from "../CampaignCard/CampaignCard";
import Spinner from "../../shared/LoadingSpinner/Spinner";

export const campaignExcerpt = (plainText) => {
  const EXCERPT_CHARS = 45;
  const EXCERPT_SENTENCES = 1;
  let sentences =
    plainText.split(".").slice(0, EXCERPT_SENTENCES).join(". ") + ".";
  return sentences.length > EXCERPT_CHARS
    ? sentences.substring(0, EXCERPT_CHARS) + "..."
    : sentences;
};

export default function CampaignSearch() {
  const [campaigns, setCampaigns] = useState([]);
  const [tag, setTag] = useState("");
  const [ageFilter, setAgeFilter] = useState("");
  const { height, width } = useWindowDimensions();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchParam, setSearchParam] = useState(
    searchParams.get("search") || ""
  );
  const [page, setPage] = useState(1);
  const [IfDataExists, setIfDataExists] = useState(true);
  const [isLoading, setIsLoading] = useState(true);

  const getAllCampaigns = async (page, search, species, age) => {
    try {
      const response = await getPaginatedCampaigns(page, search, species, age);

      if (response) {setIsLoading(false)}

      if (page === 1) {
        setCampaigns(response.results);
      } else {
        setCampaigns([
          ...campaigns,
          ...response.results,
        ])
      }

      if (!response.next) {
        setIfDataExists(false);
      } else {
        setIfDataExists(true)
      }
    } catch (error) {
      console.error(`Could not get campaigns`, error);
    }
  };

  useEffect(() => {
    getAllCampaigns(page, "", "", "");
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])

  // Handles searches
  const handleSearch = () => {
    setPage(1);
    getAllCampaigns(1, searchParam, tag, ageFilter);
  };

  // Handles breed filter
  const handleTagChange = (e) => {
    setTag(e.target.value);
    setPage(1);
    getAllCampaigns(1, searchParam, e.target.value, ageFilter);
  };

  // Handles age filter
  const handleAgeFilter = (e) => {
    setAgeFilter(e.target.value);
    setPage(1);
    getAllCampaigns(1, searchParam, tag, e.target.value);
  }
  
  // Redirects to the Campagin Feature page
  const handleClick = (campaign) => {
    navigate(
      `/campaigns/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
        campaign.animal_name
      )}/${campaign.id}`
    );
  };

  // Handles showing more campaigns if available
  const handleLoadMoreButton = (e) => {
    e.preventDefault();
    getAllCampaigns(page+1, searchParam, tag, ageFilter);
    setPage(page+1);
  }

  return (
    <div className="sm:grow flex flex-col justify-center items-center w-full !my-0 !py-32">
      <h1 className="text-orange mb-8">Find Pets in Need</h1>
      <div className="flex flex-col lg:flex-row items-center my-0 mx-auto gap-8 lg:gap-16">
        <div className="flex gap-4">
          <label className={`flex flex-col w-12 ${tag === "" && "text-orange border-b-2 border-orange pb-1"}`}>
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-all"
              value=""
              defaultChecked
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-2xl" icon={faStar} />
            <p className="m-0 mt-4">All</p>
          </label>
          <label className={`flex flex-col w-12 ${tag === "dog" && "text-orange border-b-2 border-orange pb-1"}`}>
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-dog"
              value="dog"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-2xl" icon={faDog} />
            <p className="m-0 mt-4">Dogs</p>
          </label>
          <label className={`flex flex-col w-12 ${tag === "cat" && "text-orange border-b-2 border-orange pb-1"}`}>
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-cat"
              value="cat"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-2xl" icon={faCat} />
            <p className="m-0 mt-4">Cats</p>
          </label>
          <label className={`flex flex-col w-12 ${tag === "farm" && "text-orange border-b-2 border-orange pb-1"}`}>
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-farm"
              value="farm"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-2xl" icon={faHorse} />
            <p className="m-0 mt-4">Farm</p>
          </label>
          <label className={`flex flex-col w-12 ${tag === "exotic" && "text-orange border-b-2 border-orange pb-1"}`}>
            <input
              className="opacity-0 w-0 h-0"
              type="radio"
              name="species"
              id="species-exotic"
              value="exotic"
              onChange={handleTagChange}
            />
            <FontAwesomeIcon className="text-2xl" icon={faOtter} />
            <p className="m-0 mt-4">Exotic</p>
          </label>
        </div>
        <div>
          <select
            className="p-2 border border-grey-light rounded mb-4 text-grey-dark1 outline-none flex-1"
            value={ageFilter}
            onChange={handleAgeFilter}
          >
            <option value="">All Ages</option>
            <option value="1">Newborn</option>
            <option value="2">Young</option>
            <option value="3">Adult</option>
            <option value="4">Senior</option>
            <option value="5">Unknown</option>
          </select>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row items-end my-0 mx-auto gap-4">
        <div
          className="max-w-4xl min-w-96 max-w-96:min-w-72 w-full h-9 flex flex-1 items-center m-0 mt-8 border border-grey rounded-full overflow-hidden"
        >
          <FontAwesomeIcon className="cursor-default text-grey my-0 mx-2" icon={faMagnifyingGlass} />
          <input
            type="text"
            placeholder="Type to search by name, city, or state"
            value={searchParam}
            onChange={(e) => setSearchParam(e.target.value)}
            className="flex-1 border-none outline-none"
          />
        </div>
        <button 
          className="flex justify-center item-center mx-auto h-9 px-4 py-1.5 bg-orange hover:bg-orange-dark1 shadow-xl rounded-3xl text-white font-bold text-base" 
          onClick={handleSearch}>Search</button>
      </div>
      <div
        className="flex flex-col"
      >
        {isLoading ? (
          <div className="flex flex-row mx-auto mt-16 w-full pb-24 justify-center">
            <Spinner />
          </div>
        ) : (
          <div className="w-full flex max-w-md:flex-col flex-wrap justify-center max-w-md:items-center gap-12 max-w-md:gap-4 my-0 mt-8 mx-auto py-0 px-20 max-w-md:px-4">
            {campaigns && campaigns.length > 0 ? (
              campaigns.map((campaign) => (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  handleClick={handleClick}
                />
              ))
            ) : (
              <p>Sorry, no matching results!</p>
            )}
          </div>
        )}
        
        {!isLoading && IfDataExists && <button className="flex justify-center item-center mx-auto mt-16 px-4 py-2 bg-blue hover:bg-blue-dark1 shadow-xl rounded-3xl text-white font-bold text-base " onClick={handleLoadMoreButton}>Load More</button>}
      </div>
    </div>
  );
}
