import React, { useEffect, useState } from "react";
import { signUp } from "../../api/Signup";
import { useUser } from "../../api/UserContext";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import GeneralButton from "../shared/Buttons/GeneralButton";
import classes from "./SignupPage.module.css";
import { Button, useMediaQuery } from "@mui/material";
import Spinner from "../shared/LoadingSpinner/Spinner";

function SignupPage({ props }) {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    user_type: "",
    token: "",
  });
  const [passwordError, setPasswordError] = useState("");
  const [generalError, setGeneralError] = useState("");
  const { updateUser } = useUser();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state;
  const [urlToken, setUrlToken] = useSearchParams();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    if (name === "password" || name === "confirmPassword") {
      setPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setGeneralError("");

    if (formData.password !== formData.confirmPassword) {
      setPasswordError("Passwords do not match.");
      return;
    }
    setPasswordError("");

    try {
      const signUpData = {
        ...formData,
        username: formData.email,
      };
      delete signUpData.confirmPassword;

      const data = await signUp(signUpData);
      if (data && !data.password) {
        updateUser(data);
        navigate(`/profile`);
      }
    } catch (error) {
      const errorMessage = errorHandler(error);
      setGeneralError(errorMessage);
      console.error("Error during signup:", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    // Preload the background image
    const img = new Image();
    img.src = "https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/unsplash_5gXPapBz40c-min.png";
    img.onload = () => {
      setImageLoaded(true);
    };
  }, []);

  useEffect(() => {
    if (urlToken.get("token")) {
      setFormData((prevState) => ({
        ...prevState,
        token: urlToken.get("token"),
        user_type: "nonprofit_member",
      }));
    }
  }, []);

  const matches540px = useMediaQuery("(max-width: 540px)");

  return (
    <>
      {imageLoaded ? (
        <div className="flex flex-row max-[950px]:flex-col">
          <div className="w-[60%] relative max-[1200px]:w-[50%] max-[950px]:w-full h-screen"> {/* Set height to full viewport */}
            <div className="absolute inset-0 bg-black opacity-50 z-20"></div>
            <p className={`absolute top-[50%] left-[13%] z-30 text-white !text-[4rem] ${classes.header} max-[1200px]:top-[40%] max-[1200px]:left-[5%] max-[950px]:left-[17%] max-[780px]:left-[10%] max-[750px]:left-[18%] max-[750px]:top-[50%] max-[780px]:!text-[2.5rem] max-[520px]:left-[12%] max-[400px]:!text-[2rem] max-[400px]:left-[8%]`}>
              Join Sponsor a Pet
            </p>
            <img
              src={"https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/unsplash_5gXPapBz40c-min.png"}
              alt=""
              className="object-cover bg-no-repeat w-full h-full relative"
            />
          </div>

          <form className={`${classes.signup_card} !w-[40%] max-[1200px]:!w-[50%] max-[950px]:!w-full`} onSubmit={handleSubmit}>
            <h2 className="text-3xl font-bold text-white mb-4">
              Sign Up
            </h2>
            <div className="flex flex-row w-full my-2 max-[950px]:flex-col max-[950px]:my-0">
              <div className="w-1/2 max-[950px]:w-full">
                <label className="font-semibold max-[950px]:!ml-[40px]">
                  First Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  required
                  name="first_name"
                  value={formData.first_name}
                  onChange={handleChange}
                  placeholder="First Name"
                />
              </div>
              <div className="w-1/2 max-[950px]:w-full">
                <label className="font-semibold max-[950px]:!ml-[40px]">
                  Last Name <span className="text-red-500">*</span>
                </label>
                <input
                  type="text"
                  required
                  name="last_name"
                  value={formData.last_name}
                  onChange={handleChange}
                  placeholder="Last Name"
                />
              </div>
            </div>

            <div className="flex flex-row w-full my-2 max-[950px]:flex-col max-[950px]:my-0">
              <div className="w-1/2 max-[950px]:w-full">
                <label className="font-semibold max-[950px]:!ml-[40px]">
                  Email <span className="text-red-500">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  required
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              <div className="w-1/2 max-[950px]:w-full">
                <label className="font-semibold max-[950px]:!ml-[40px]">Phone Number</label>
                <input
                  type="number"
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  placeholder="Phone Number"
                />
              </div>
            </div>

            <div className="flex flex-row w-full my-2 max-[950px]:flex-col max-[950px]:my-0">
              <div className="w-1/2 max-[950px]:w-full">
                <label className="font-semibold max-[950px]:!ml-[40px]">
                  Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
              </div>
              <div className="w-1/2 max-[950px]:w-full">
                <label className="font-semibold max-[950px]:!ml-[40px]">
                  Confirm Password <span className="text-red-500">*</span>
                </label>
                <input
                  type="password"
                  name="confirmPassword"
                  value={formData.confirmPassword}
                  onChange={handleChange}
                  placeholder="Confirm Password"
                />
              </div>
            </div>

            {!urlToken.get("token") ? (
              <>
                <label className="font-semibold max-[950px]:!ml-[40px] max-[950px]:mr-auto">
                  Do you intend to set up a nonprofit?{" "}
                  <span className="text-red-500">*</span>
                </label>
                <select
                  name="user_type"
                  value={formData.user_type}
                  onChange={handleChange}
                  className="max-[950px]:!w-[90%]"
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  <option value="nonprofit_member">Yes</option>
                  <option value="regular_user">No</option>
                </select>
              </>
            ) : null}

            {passwordError && (
              <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
                {passwordError}
              </div>
            )}
            {generalError && (
              <div className="text-[var(--default-red)] m-[10px] whitespace-pre-wrap">
                {generalError}
              </div>
            )}

            <div className="flex justify-center">
              <GeneralButton
                colour="orange"
                className="rounded-3xl px-4 py-2 !text-xl w-fit font-semibold"
                type="submit"
              >
                Sign Up
              </GeneralButton>
            </div>

            <p className="font-semibold text-center m-4 text-white">
              Already have an account?{" "}
              <Link
                to="/login"
                state={{ back: `${state && state.back ? state.back : "/"}` }}
                className="underline font-semibold text-white hover:text-orange"
              >
                Login
              </Link>
            </p>
          </form>
        </div>
      ) : (
        <div className="flex justify-center items-center h-screen">
          <Spinner />
        </div>
      )}
    </>
  );
}

export default SignupPage;
