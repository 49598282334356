import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const createStripeSubscription = async (userId, data) => {
  try {
    const config = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${API_URL}/users/affiliates/${userId}/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const affiliateSuccess = async (userId) => {
  try {
    const config = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${API_URL}/users/affiliates/${userId}/success/`,
      config,
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const affiliateInformation = async (userIds) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        "Content-Type": "application/json",
      },
    };

    const response = await axios.get(`${API_URL}/users/affiliates/`, config);
    const filteredReferrals = response.data.filter((affiliate)=>{
      return userIds.includes(affiliate.referral);
    })

    return filteredReferrals;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }}

export const affiliateSubscriptionUpdate = async (userId, data) => {
  try {
    const config = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios.post(
      `${API_URL}/users/affiliates/${userId}/update/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};

export const freeAffiliateSignup = async (user_id) => {
  try {
    const response = await axios.post(`${API_URL}/users/affiliates/free/`, 
      {
        user_id: user_id,
      }
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
}

export const AffiliateCommissionGivebackUpdate = async (userId) => {
  try {
    const config = {
      headers: {
        "Authorization": `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios.post(
      `${API_URL}/users/affiliates/${userId}/giveback/`,
      userId,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};