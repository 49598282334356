import { useParams } from "react-router";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getCampaignbyId} from "../../../api/Campaigns";
import { faHeart, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { getNpInfo } from "../../../api/NonprofitInfo";
import Slider from "react-slick";
import { generateDonation } from "../../../api/GenerateDonation";
import { donationSuccess } from "../../../api/DonationSuccess";
import { parseDescription } from "../../shared/Utils/ParseDescription";
import SuccessModal from "./SuccessModal/SuccessModal";
import NotFound from "../../shared/Utils/NotFound";
import { useSelector } from "react-redux";
import LoginSignupModal from "../../shared/LoginSignupModal/LoginSignupModal";
import Spinner from "../../shared/LoadingSpinner/Spinner";
import classes from "./CampaignFeature.module.css";
import DonationBox from "../../shared/DonationBox/DonationBox";
import CampaignDonorsBlock from "../CampaignDonorsBlock/CampaignDonorsBlock";
import { extractMissionStatement } from "../../shared/Utils/ExtractMissionStatement";

const DonationProgress = ({ campaign }) => {
  const [showTooltip, setShowTooltip] = useState(false);

  // Calculate the progress percentage and remaining amount
  const progressPercentage = Math.min(
    (campaign.donation_amount / campaign.donation_goal) * 100,
    100
  ).toFixed(2);
  const remainingAmount = campaign.donation_goal - campaign.donation_amount;

  // Function to handle link sharing
  const handleShareClick = () => {
    // Copy the current URL to the clipboard
    const shareLink = window.location.href;
    navigator.clipboard.writeText(shareLink);

    // Show tooltip
    setShowTooltip(true);

    // Hide tooltip after 3 seconds
    setTimeout(() => setShowTooltip(false), 3000);
  };

  return (
    <div className="border rounded-xl p-4 shadow-lg !w-full lg:mx-20 relative">
      <div className="text-5xl my-4 mx-8 font-bold text-left text-blue">
        ${campaign.donation_amount ? (
          campaign.donation_amount.toLocaleString()
        ) : (
          "0"
        )}
      </div>
      <div className="relative mt-2 mx-8">
        <div className="h-2 bg-gray-200 rounded-full">
          <div
            className="h-2 bg-blue rounded-full"
            style={{ width: `${progressPercentage}%` }}
          />
        </div>
      </div>
      <div className="flex justify-between text-sm mx-8 text-gray-700 mt-2">
        <span>Goal</span>
        <span>Remaining</span>
      </div>
      <div className="flex justify-between mx-8 text-lg font-semibold">
        <span>${campaign.donation_goal.toLocaleString()}</span>
        <span>${remainingAmount ? (
          remainingAmount.toLocaleString()
        ) : (
          "0"
        )}</span>
      </div>
      <button
        onClick={handleShareClick}
        className="flex items-center justify-center ml-8 mt-4 py-2 px-4 bg-blue text-white font-semibold rounded-full shadow hover:bg-blue-dark1 relative"
      >
        Share
        <FontAwesomeIcon icon={faHeart} className="ml-2 text-orange" />
      </button>
      {showTooltip && (
        <div className="flex transform mt-2 ml-8 bg-green-500 w-fit text-white text-sm font-semibold rounded px-2 py-1 shadow-lg">
          Link copied to clipboard!
        </div>
      )}
    </div>
  );
};

export default function CampaignFeature({
  isOpen,
  onClose,
  campaignData,
  imagePreviews,
  graphicImagePreviews,
}) {
  const { nonprofit_name, animal_name, campaign_id } = useParams();
  const [campaign, setCampaign] = useState(null);
  const [nonprofit, setNonprofit] = useState(null);
  const [npStatement, setNpStatement] = useState([]);
  const [ifReadMore, setIfReadMore] = useState(false);
  const [clientSecret, setClientSecret] = useState(null);
  const [, setDonationAmount] = useState(0);
  const [donationId, setDonationId] = useState(0);
  const [stripe, setStripe] = useState(null);
  const [elements, setElements] = useState(null);
  const [paymentResponse, setPaymentResponse] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [localUserData, setLocalUserData] = useState(null);
  const [isStripeLoading, setIsStripeLoading] = useState(true);
  const [blurredImages, setBlurredImages] = useState({});
  const [error, setError] = useState(null);
  const [coverFee, setCoverFee] = useState(true);
  const [prevAmount, setPrevAmount] = useState(0);
  const [customAmount, setCustomAmount] = useState("");
  const [showModal, setShowModal] = useState(false);
  const userData = useSelector((state) => state.userData.userData);

  const [isLoginSignupModalOpen, setLoginSignupModalOpenModalOpen] = useState(false);
  const [loginOrSignup, setLoginOrSignup] = useState("");
  const [completedDonations, setCompletedDonations] = useState([]);

  // States for preview campaign feature
  const [ifPreview, setIfPreview] = useState(false);
  const [allImages, setAllImages] = useState([])
  const [videoUrls, setVideoUrls] = useState([]);
  const [blurredPreviewImages, setBlurredPreviewImages] = useState({});

  const navigate = useNavigate();

  function terminalWarnings() {
    return isStripeLoading;
  }

  terminalWarnings();

  useEffect(() => {
    if (Object.keys(userData).length !== 0) {
      setLocalUserData(JSON.parse(userData));
    }
  }, [userData]);

  // Handles and sets state for the Preview Modal
  useEffect(() => {
    if (isOpen) {
      setIfPreview(true);

      if (campaignData?.images) {
        const images = [
          ...imagePreviews.map((image) => ({ image, graphic: false, show: true })),
          ...graphicImagePreviews.map((image) => ({
            image,
            graphic: true,
            show: blurredPreviewImages[image] || false,
          })),
        ];
  
        setAllImages(images);
      }
  
      if (campaignData?.video_urls) {
        const regex = new RegExp(/^(https?:\/\/)/);
        const validateUrls = (urls) => {
          if (urls) {
            let validatedUrls = urls.split(",");
            for (let i = 0; i < validatedUrls.length; i++) {
              validatedUrls[i] = validatedUrls[i].trim();
              if (regex.test(validatedUrls[i])) {
                if (
                  validatedUrls[i].includes("youtube") &&
                  validatedUrls[i].indexOf("watch?v=") !== -1
                ) {
                  validatedUrls[i] = validatedUrls[i].replace("watch?v=", "embed/");
                  validatedUrls[i] = validatedUrls[i].slice(
                    0,
                    validatedUrls[i].indexOf("&")
                  );
                }
              } else {
                validatedUrls[i] = "URL not valid";
              }
            }
            setVideoUrls(validatedUrls);
          }
        };
  
        validateUrls(campaignData.video_urls);
      }
    }
  }, [isOpen, blurredPreviewImages])

  useEffect(() => {
    const fetchAll = async () => {
      try {
        const tempLoadedCampaign = await getCampaignbyId(campaign_id);

        if (!tempLoadedCampaign) {
          throw new Error("Campaign not found");
        }
        if (!tempLoadedCampaign) return;
        const tempLoadedNonprofit = await getNpInfo(
          tempLoadedCampaign.nonprofit
        );

        const donations = tempLoadedCampaign.donations_details.filter(
          (donation) => {
            return donation["status"] === "completed";
          }
        );
        const sentences = extractMissionStatement(tempLoadedNonprofit.mission_stmt, true);
        setNpStatement(sentences);
        setCampaign(tempLoadedCampaign);
        setNonprofit(tempLoadedNonprofit);
        setCompletedDonations(donations);
        return tempLoadedCampaign;
      } catch (error) {
        if (ifPreview) {
          setError(null);
        } else {
          setError(error);
        }
      }
    };
    if (!isOpen) {
      try {
        fetchAll();
      } catch (error) {
        console.error("Error in CampaignFeature:", error);
      }
    }
  }, [nonprofit_name, animal_name, campaign_id]);

  const handleDonationSubmit = async (event) => {
    event.preventDefault();

    let amountToDonate = selectedOption || parseFloat(customAmount);

    if (coverFee) {
      amountToDonate += parseFloat((amountToDonate * 0.03).toFixed(2));
    }

    setDonationAmount(amountToDonate);

    const initializePayment = async (campaign) => {
      if (!campaign || !campaign.donation_goal) return;

      const data = {
        campaign: campaign_id,
        amount: amountToDonate,
      };
      try {
        const response = await generateDonation(data);
        setClientSecret(response.client_secret);
        setDonationId(response.donation_id);
      } catch (error) {
        console.error("Error: ", error);
      }
    };

    initializePayment(campaign);
  };

  useEffect(() => {
    if (clientSecret) {
      const stripeInstance = window.Stripe(process.env.REACT_APP_STRIPE_KEY);
      const appearance = {
        /* appearance */
      };
      const options = {
        layout: {
          type: "tabs",
          defaultCollapsed: false,
        },
      };
      const elementsInstance = stripeInstance.elements({
        clientSecret,
        appearance,
      });
      const cardElement = elementsInstance.create("payment", options);
      cardElement.mount("#payment-element");

      cardElement.on("ready", () => {
        setIsStripeLoading(false);
      });

      setStripe(stripeInstance);
      setElements(elementsInstance);
    }
  }, [clientSecret]);

  // Shows blurred previews images
  const handlePreviewImageClick = (image) => {
    setBlurredPreviewImages((prevState) => ({
      ...prevState,
      [image.image]: true,
    }));
  };

  const handleSubmitPayment = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) return;

    const paymentElement = elements.getElement("payment");

    if (!paymentElement) {
      setPaymentResponse("Payment Element is not loaded.");
      return;
    }

    setIsStripeLoading(true);

    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: window.location.href,
        },
        redirect: "if_required",
      });

      if (error) {
        setPaymentResponse(error.message);
      } else {
        if (paymentIntent && paymentIntent.status === "succeeded") {
          setPaymentResponse({ status: "success" });
          setShowModal(true);
          await donationSuccess({ donation_id: donationId });
        } else {
          setPaymentResponse({ error: error });
        }
      }
    } catch (error) {
      setPaymentResponse("An error occurred during payment.");
      console.error(error);
    } finally {
      setIsStripeLoading(false);
    }
  };

  const handleOptionClick = (amount) => {
    if (customAmount) {
      setCoverFee(false);
      setCustomAmount("");
    }

    if (selectedOption === amount) {
      setSelectedOption(null);
      setPrevAmount(0);
      setDonationAmount(0);
    } else {
      setSelectedOption(amount);
      setPrevAmount(amount);
      setDonationAmount(amount);
      setCoverFee(false);
    }
  };

  const handleImageClick = (imageUrl) => {
    setBlurredImages((prevState) => ({
      ...prevState,
      [imageUrl]: prevState[imageUrl] === false ? true : false,
    }));
  };

  const handleInputChange = (e) => {
    setPrevAmount(e.target.value);
    setCustomAmount(e.target.value);
    setDonationAmount(parseFloat(e.target.value));
    if (selectedOption) {
      setSelectedOption(null);
      setCoverFee(false);
    }
  };

  const handleCoverFeeChange = (e) => {
    setCoverFee(e.target.checked);
    const fee = parseFloat((prevAmount * 0.03).toFixed(2));
    if (e.target.checked) {
      setDonationAmount(Number(prevAmount) + Number(fee));
    } else {
      setDonationAmount(Number(prevAmount));
    }
  };

  let slideshowSettings = {
    dots: true,
    infinite: ifPreview
    ? (allImages.length > 0 || videoUrls.length > 0) && (allImages.length + videoUrls.length > 1)
    : (campaign && campaign.campaign_images)
      ? campaign.campaign_images.length > 1
      : false,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    speed: 500,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const closeModal = () => {
    setShowModal(false);
    window.location.reload();
  };

  if (error) {
    return <NotFound />;
  }

  const openLoginSignupModal = (type) => {
    setLoginOrSignup(type);
    setLoginSignupModalOpenModalOpen(true);
    document.body.style.overflow = "hidden";
  };

  const closeLoginSignupModal = () => {
    setLoginSignupModalOpenModalOpen(false);
    document.body.style.overflow = "";
  };

  const handleFormSuccess = (success) => {
    if (success) {
      window.location.reload();
    }
  };

  if ((!campaign || !nonprofit) && !ifPreview) {
    return (
      <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
        <Spinner />
      </div>
    )
  }

  // Handles switching from 'Read more' to 'Read less'
  const handleReadMoreButton = () => {
    return ifReadMore ? setIfReadMore(false) : setIfReadMore(true);
  };

  return (
    <div className={`${ifPreview && "relative main-div w-full h-full overflow-auto box-border"}`}>
      <div className={`${ifPreview ? "!fixed top-0 left-0 z-50 w-full h-full bg-white overflow-y-auto !m-0 pt-0" : ""}`}>
          {/* Top section of Preview Campaign */}
          {ifPreview && (
            <section className={`md:flex-col md:h-96 flex lg:flex-row p-0 pb-4 w-full lg:h-16 mb-16`}>
              <h1 
                className={`md:w-4/5 md:text-left md:ml-4 md:pt-4 md:pb-4 cursor-pointer lg:w-1/5 text-orange lg:p-0 lg:pb-4`}
                onClick={() => {
                  onClose(); 
                  setIfPreview(false);}}
              >
                <FontAwesomeIcon 
                  icon={faArrowLeft} 
                  /> Exit Preview
              </h1>
              <div
                className={`flex items-center justify-center grow w-full bg-stone-400 text-white`}
              >
                <p>This is a preview of your campaign.</p>
              </div>
            </section>
          )}

          <div className={`flex flex-col lg:flex-row !w-full lg:mx-6 mt-24 sm:flex-grow ${ifPreview ? "!mt-0" : ""}`}>
            {/* First Column - 60% Width */}
            <div className="flex flex-col lg:ml-40 mt-12 w-full lg:w-1/2 align-left">
              {ifPreview && <h1 className="text-left !text-orange">{campaignData.animal_name}</h1>}
              {campaign && <h1 className="text-left !text-orange">{campaign.animal_name}</h1>}
              {ifPreview ? (
                allImages.length > 0 || videoUrls.length > 0 ? (
                  <Slider
                    {...slideshowSettings}
                    className={`${classes.campaign_media_carousel} relative w-100 h-[40vh] flex items-center justify-center`}
                  >
                    {allImages.length > 0 &&
                      allImages.map((img, index) => (
                        <div
                          key={index}
                          className="w-100 h-[40vh] flex items-center justify-center relative"
                        >
                          <img
                            src={img.image}
                            alt={campaignData.animal_name}
                            className={`object-scale-down w-full h-full ${
                              img.graphic && !img.show ? "relative cursor-pointer blur-sm" : ""
                            }`}
                          />
                          {img.graphic && !img.show && (
                            <button
                              className="absolute inset-0 flex items-center justify-center text-sm text-white bg-black bg-opacity-50 rounded"
                              onClick={() => handlePreviewImageClick(img)}
                            >
                              Show
                            </button>
                          )}
                        </div>
                      ))}
                    {videoUrls.length > 0 &&
                      videoUrls.map((url, index) => (
                        <div key={index} className="w-100 h-[40vh] flex items-center justify-center">
                          {url !== "URL not valid" ? (
                            <iframe
                              src={url}
                              title={`Success Video ${index + 1}`}
                              frameBorder="0"
                              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                              allowFullScreen
                              className="object-scale-down w-full h-full"
                            />
                          ) : (
                            <p className="flex justify-center items-center w-full h-full text-red">
                              Invalid URL
                            </p>
                          )}
                        </div>
                      ))}
                  </Slider>
                ) : (
                  "" // Renders empty string if ifPreview is true and there aren't any images/videos
                )
              ) : (
                campaign?.campaign_images && campaign.campaign_images.length > 0 ? (
                  <Slider
                    {...slideshowSettings}
                    className={`${classes.campaign_media_carousel} relative w-100 h-[40vh] flex items-center justify-center`}
                  >
                    {campaign.campaign_images
                      .sort((a, b) => a.order - b.order)
                      .map((image, index) => (
                        <div key={index} className="w-100 h-[40vh] flex items-center justify-center">
                          <div className="relative w-100 h-[40vh]">
                            <img
                              src={image.image_url}
                              alt={campaign.animal_name}
                              className={`object-scale-down w-full h-full ${
                                image.graphic && blurredImages[image.image_url] !== false
                                  ? "cursor-pointer blur-md"
                                  : ""
                              }`}
                            />
                            {image.graphic && blurredImages[image.image_url] !== false && (
                              <span
                                className="cursor-pointer absolute text-sm text-center text-black"
                                onClick={() => handleImageClick(image.image_url)}
                              >
                                Show
                              </span>
                            )}
                          </div>
                        </div>
                      ))}
                    {campaign.video_urls &&
                      campaign.video_urls.split(",").map((videoUrl, index) => (
                        <div key={index} className="w-100 h-[40vh] flex items-center justify-center">
                          <iframe
                            src={videoUrl.trim()}
                            title={`Campaign Video ${index + 1}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            className="object-scale-down w-full h-full"
                          />
                        </div>
                      ))}
                  </Slider>
                ) : null
              )}
              <div>
                {ifPreview || (campaign && campaign.description) ? (
                  <div className="flex flex-col">
                    <h1 className="!text-orange text-left my-8">
                      About {ifPreview ? campaignData?.animal_name || "Animal Name" : campaign.animal_name}
                    </h1>
                    {ifPreview
                      ? campaignData?.description
                        ? parseDescription === campaignData.description
                          ? <p className="text-left">{campaignData.description}</p>
                          : <div className="text-left"
                              dangerouslySetInnerHTML={{
                                __html: parseDescription(campaignData.description),
                              }}
                            />
                        : <p>Description</p>
                      : parseDescription === campaign.description
                        ? <p className="text-left">{campaign.description}</p>
                        : <div className="text-left"
                            dangerouslySetInnerHTML={{
                              __html: parseDescription(campaign.description),
                            }}
                          />
                    }
                  </div>
                ) : (
                  ""
                )}
              </div>
              <hr className="mt-8 bg-gray-900 border-t-2" />
              {nonprofit && <h1 className="!text-orange text-center mt-8">{nonprofit.org_name}</h1>}
              {npStatement.length > 3 ? (
                <>
                  <p>{ifReadMore ? npStatement : npStatement.slice(0, 3)}</p>
                  <button
                    className="font-bold rounded-3xl bg-blue mx-auto text-white text-sm px-4 py-2 hover:drop-shadow	hover:bg-blue-dark1"
                    onClick={() => handleReadMoreButton()}
                  >
                    {ifReadMore ? "Read less.." : "Read more..."}
                  </button>
                </>
              ) : (
                <p>{npStatement}</p>
              )}
              {nonprofit?.intro_video && 
                <div className="!w-full !h-[50vh] border border-orange mt-8 mx-auto rounded-3xl flex items-center justify-center">
                  <iframe
                    src={nonprofit.intro_video}
                    title="Campaign Intro"
                    allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    className="object-scale-down w-full h-full rounded-3xl"
                  />
                </div>
              }
              <div className="flex items-center justify-center">
                {nonprofit && <DonationBox nonprofit={nonprofit} className />}
              </div>
            </div>

            {/* Second Column - 40% Width */}
            <div className="flex flex-col my-12 w-full lg:w-1/2 lg:mr-40">
              {ifPreview ? (
                <DonationProgress campaign={campaignData} />
              ) : (
                <DonationProgress campaign={campaign} />
              )}
              
              <div className="flex flex-col lg:ml-20 border my-12 w-full rounded-3xl border-gray shadow-lg border-1">
                <h1 className="my-8 !text-orange">
                  {ifPreview
                    ? campaignData?.donation_box_title || "Donate"
                    : campaign?.donation_box_title || "Donate"}
                </h1>

                {!ifPreview && paymentResponse?.status && showModal && (
                  <SuccessModal message="Payment succeeded!" onClose={closeModal} showModal={showModal} />
                )}

                <div className="w-full flex flex-col justify-center items-center mb-4 rounded-lg">
                  <div className="flex flex-col justify-center items-center">
                    {!clientSecret && 
                      <div className="grid grid-cols-3 gap-8">
                        {[10, 25, 35, 50, 75, 100].map((amount) => (
                          <button
                            key={amount}
                            className={`border border-gray-400 text-2xl px-4 py-2 rounded-3xl ${selectedOption === amount ? "!bg-orange !font-semibold !text-white" : ""}`}
                            type="button"
                            disabled={ifPreview}
                            onClick={!ifPreview ? () => handleOptionClick(amount) : undefined}
                          >
                            ${amount}
                          </button>
                        ))}
                      </div>
                    } 
                    {!clientSecret ? (
                      <form
                        id={classes.amount_form}
                        className="w-full no-underline"
                        onSubmit={!ifPreview ? handleDonationSubmit : undefined}
                      >
                        <div className="my-12">
                          <input
                            type="number"
                            id="donation-amount"
                            name="donation-amount"
                            placeholder="Custom donation amount"
                            className="border border-gray-400 p-2 rounded-3xl"
                            value={customAmount}
                            onChange={!ifPreview ? handleInputChange : undefined}
                            disabled={ifPreview}
                            min={1}
                          />
                          <div className="flex flex-row gap-2 mt-2 pl-4 pr-4">
                            <label htmlFor="coverFee" className="text-sm">
                              Would you like to cover the transaction fee of (${(prevAmount * 0.03).toFixed(2)})?
                            </label>
                            <div className="-mt-4">
                              <input
                                type="checkbox"
                                id="coverFee"
                                checked={!ifPreview ? coverFee : undefined}
                                onChange={!ifPreview ? handleCoverFeeChange : undefined}
                                disabled={ifPreview}
                              />
                            </div>
                          </div>
                          {!ifPreview && localUserData && (
                            <button className="bg-orange hover:bg-orange-dark1 rounded-3xl px-4 py-2 text-xl font-semibold text-white" id="submit-amount">Donate</button>
                          )}
                        </div>
                      </form>
                    ) : (
                      <form
                      id="payment-form"
                      className={classes.payment_form}
                      onSubmit={handleSubmitPayment}
                      >
                        <div id="payment-element"></div>
                        <button className="bg-orange hover:bg-orange-dark1 rounded-3xl px-4 py-2 mt-4 text-xl font-semibold text-white" type="submit">
                          Complete Donation
                        </button>
                      </form>
                    )}

                    {!ifPreview && !localUserData && (
                      <div>
                        <h4 className="mb-9">
                          {isLoginSignupModalOpen && (
                            <LoginSignupModal
                              loginOrSignup={loginOrSignup}
                              isOpen={openLoginSignupModal}
                              onClose={closeLoginSignupModal}
                              onFormSuccess={handleFormSuccess}
                            />
                          )}
                          <button className="underline text-orange" onClick={() => openLoginSignupModal("signup")}>
                            Sign Up
                          </button>{" "}
                          or{" "}
                          <button className="underline text-orange" onClick={() => openLoginSignupModal("login")}>
                            login
                          </button>{" "}
                          to donate
                        </h4>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {campaign && <CampaignDonorsBlock campaign={campaign} />}
            </div>
          </div>
      </div>
    </div>
  );
}