import React, { useState, useEffect, useRef } from "react";
import { submitCampaign } from "../../../api/SubmitCampaign";
import QRCodeModal from "../QRCodeModal/QRCodeModal";
import { urlifyName } from "../../shared/Utils/PageUrl";
import classes from "./CampaignModal.module.css";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import SharedTooltip from "../../shared/Tooltip/SharedTooltip";
import Quill from "quill";
import Editor from "./Editor/Editor";
import { Grid, Card, CardMedia } from "@mui/material";
import {
  SortableContainer,
  SortableElement,
  arrayMove,
} from "react-sortable-hoc";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import Compressor from "compressorjs";
import CampaignFeature from "../../Campaigns/CampaignFeature/CampaignFeature";

const Delta = Quill.import("delta");

const SortableItem = SortableElement(({ value }) => (
  <Grid item xs={4}>
    <Card>
      <CardMedia
        component="img"
        height="140"
        image={value}
        alt="campaign image"
      />
    </Card>
  </Grid>
));

const SortableList = SortableContainer(({ items }) => {
  return (
    <div>
      <Grid container spacing={2} className="overflow-auto">
        {items.map((value, index) => (
          <SortableItem key={`item-${index}`} index={index} value={value} />
        ))}
      </Grid>
    </div>
  );
});

const ageOptions = {
  Newborn: 1,
  Young: 2,
  Adult: 3,
  Senior: 4,
  Unknown: 5,
};

const maxSize = 10 * 1024 * 1024;

const CampaignForm = ({
  nonprofitId,
  onSuccess,
  nonprofitName,
  qrModalOpen,
  campaignUrl,
  setQrModalOpen,
  nonprofitAddress,
  setCampaigns,
  onClose,
}) => {
  const [campaignData, setCampaignData] = useState({
    nonprofit: "",
    animal_name: "",
    campaign_title: "",
    description: "", // Initially empty, will be updated by Quill
    donation_goal: "",
    age: "",
    color: "",
    donation_box_title: "",
    breed: "",
    gender: "",
    species: "",
    video_urls: "",
    images: [],
    graphic_images: [],
    success: "",
    error: "",
  });

  const [tooltipKey, setTooltipKey] = useState(0);
  const [loading, setLoading] = useState(false);
  const [createError, setCreateError] = useState("");
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [imagePreviews, setImagePreviews] = useState([]);
  const [graphicImagePreviews, setGraphicImagePreviews] = useState([]);
  const [compressedFile, setCompressedFile] = useState(null);
  const fileInputRef = useRef();
  const graphicFileInputRef = useRef();
  const [selectedFileCount, setSelectedFileCount] = useState(0);
  const [selectedGraphicFileCount, setSelectedGraphicFileCount] = useState(0);

  useEffect(() => {
    setTooltipKey((prevKey) => prevKey + 1);
  }, [campaignData.donation_box_title]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    setCampaignData((prevState) => ({
      ...prevState,
      images: arrayMove(prevState.images, oldIndex, newIndex),
    }));
    setImagePreviews((prevState) => arrayMove(prevState, oldIndex, newIndex));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    let finalValue = name === "age" ? ageOptions[value] : value;

    if (name === "donation_goal" && parseFloat(value) < 0) {
      return;
    }

    setCampaignData((prevState) => ({
      ...prevState,
      [name]: finalValue,
    }));
  };

  const handleImageChange = (event) => {
    const files = Array.from(event.target.files);
    setSelectedFileCount(files.length);
    let error = "";
    let isTooLarge = false;
    setCreateError("");

    const validFiles = files.filter((file) => {
      setCreateError("");
      if (file.size > maxSize) {
        setCreateError(
          "File size is too large (Compressed File must be under 10 MB)."
        );
        error = "Compressed File size is too large (Must be under 10 MB).";
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (isTooLarge) {
      setCampaignData((prevState) => ({ ...prevState, error: error }));
    } else {
      const compressFiles = async (validFiles) => {
        const compressedFiles = await Promise.all(
          validFiles.map(async (file) => {
            return new Promise((resolve) => {
              //Compressor is async for success
              new Compressor(file, {
                quality: 0.2,
                success: (compressedResult) => {
                  resolve(compressedResult);
                },
              });
            });
          })
        );

        return compressedFiles;
      };

      const handleFileCompression = async (validFiles) => {
        try {
          validFiles = await compressFiles(validFiles);
          const newFiles = [];
          validFiles.forEach((blob) => {
            newFiles.push(
              new File([blob], blob.name, {
                type: blob.type,
              })
            );
          });

          const newImages = newFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
          }));

          setCampaignData((prevState) => ({
            ...prevState,
            images: [...prevState.images, ...newImages],
            error: "",
          }));

          const imagePreviews = newFiles.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
          });

          Promise.all(imagePreviews).then((previews) => {
            setImagePreviews((prevPreviews) => [...prevPreviews, ...previews]);
          });
        } catch (error) {
          console.error("Error during file compression:", error);
        }
      };
      handleFileCompression(validFiles);
    }
  };

  const handleGraphicImageChange = (event) => {
    setCreateError("");
    const files = Array.from(event.target.files);
    setSelectedGraphicFileCount(files.length);
    let error = "";
    let isTooLarge = false;
    setCreateError("");

    const validFiles = files.filter((file) => {
      if (file.size > maxSize) {
        setCreateError(
          "File size is too large (Submitted File must be under 10 MB)."
        );
        error = "Submitted File size is too large (Must be under 10 MB).";
        isTooLarge = true;
        return false;
      }
      return true;
    });

    if (isTooLarge) {
      setCampaignData((prevState) => ({ ...prevState, error: error }));
    } else {
      const compressFiles = async (validFiles) => {
        const compressedFiles = await Promise.all(
          validFiles.map(async (file) => {
            return new Promise((resolve) => {
              //Compressor is async for success
              new Compressor(file, {
                quality: 0.2,
                success: (compressedResult) => {
                  resolve(compressedResult);
                },
              });
            });
          })
        );

        return compressedFiles;
      };

      const handleFileCompression = async (validFiles) => {
        try {
          validFiles = await compressFiles(validFiles);

          const newFiles = [];
          validFiles.forEach((blob) => {
            newFiles.push(
              new File([blob], blob.name, {
                type: blob.type,
              })
            );
          });

          const newImages = newFiles.map((file) => ({
            file,
            url: URL.createObjectURL(file),
          }));

          setCampaignData((prevState) => ({
            ...prevState,
            graphic_images: [...prevState.graphic_images, ...newImages],
            error: "",
          }));

          const imagePreviews = newFiles.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            return new Promise((resolve) => {
              reader.onloadend = () => {
                resolve(reader.result);
              };
            });
          });

          Promise.all(imagePreviews).then((previews) => {
            setGraphicImagePreviews((prevPreviews) => [
              ...prevPreviews,
              ...previews,
            ]);
          });
        } catch (error) {
          console.error("Error during file compression:", error);
        }
      };
      handleFileCompression(validFiles);
    }
  };

  const handleEditorChange = (content) => {
    setCampaignData((prevState) => ({
      ...prevState,
      description: content,
    }));
  };

  const submitCampaignData = async (e) => {
    e.preventDefault();
    setLoading(true);

    /* Enforce at least 4 total images
    Cases:
    - 4 normal, 0 graphic > OK
    - 3 normal, 1 graphic > OK
    - Anything else is error if normal < 3 */
    if (campaignData.images.length < 4) {
      if (campaignData.graphic_images.length === 0) {
        setCreateError(
          "Please upload at least 4 normal images or 3 normal with 1 graphic image."
        );
        setCampaignData((prevState) => ({
          ...prevState,
          error:
            "Please upload at least 4 normal images or 3 normal with 1 graphic image.",
        }));
        setLoading(false);
        return;
      } else if (
        campaignData.graphic_images.length > 0 &&
        campaignData.images.length < 3
      ) {
        setCreateError(
          "Please upload at least 4 normal images or 3 normal with 1 graphic image."
        );
        setCampaignData((prevState) => ({
          ...prevState,
          error:
            "Please upload at least 4 normal images or 3 normal with 1 graphic image.",
        }));
        setLoading(false);
        return;
      }
    }

    const formData = new FormData();

    for (const key in campaignData) {
      if (key !== "images" && key !== "graphic_images" && key !== "error") {
        if (key === "description") {
          formData.append(key, JSON.stringify(campaignData[key])); // HTML as string to API
        } else {
          formData.append(key, campaignData[key]);
        }
      }
    }

    for (let i = 0; i < campaignData.images.length; i++) {
      const image = campaignData.images[i];
      if (image.file.size <= maxSize) {
        formData.append("images", image.file);
        formData.append(
          "orders",
          campaignData.images.indexOf(campaignData.images[i])
        );
      } else {
        setCampaignData((prevState) => ({
          ...prevState,
          error: "File size is too large. Please select smaller files.",
        }));
        setLoading(false);
        return;
      }
    }

    const imagesLength = campaignData.images.length;

    for (let i = 0; i < campaignData.graphic_images.length; i++) {
      const graphicImage = campaignData.graphic_images[i];
      if (graphicImage.file.size <= maxSize) {
        formData.append("graphic_images", graphicImage.file);
        formData.append("graphic_img_order", imagesLength + i);
      } else {
        setCampaignData((prevState) => ({
          ...prevState,
          error: "File size is too large. Please select smaller files.",
        }));
        setLoading(false);
        return;
      }
    }

    formData.append("nonprofit", nonprofitId);

    try {
      const response = await submitCampaign(formData);
      if (response.animal_name === campaignData.animal_name) {
        setCreateError("");
        setCampaignData((prevState) => ({
          ...prevState,
          success:
            'Campaign created successfully! Email your donor list from the "Dashboard"',
          error: "",
        }));
        setCampaigns((prev) => [...prev, response]);
        onSuccess(
          `/campaigns/${urlifyName(nonprofitName)}/${urlifyName(
            response.animal_name
          )}/${response.id}`
        );
      }
    } catch (error) {
      console.error(error);
      const errorMessage = errorHandler(error);
      const errorMessageWithBreaks = lineBreaks(errorMessage);
      setCreateError(errorMessageWithBreaks);
    } finally {
      setLoading(false);
    }
  };

  const handleOpenPreview = () => {
    setPreviewModalOpen(true);
    
    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "hidden";
    }
  };

  const handleClosePreview = () => {
    setPreviewModalOpen(false);

    const createCampaignContent = document.querySelector(
      `.${classes.campaign_modal_overlay}`
    );
    if (createCampaignContent) {
      createCampaignContent.style.overflow = "auto";
    }
  };

  return (
    <>
      <form onSubmit={submitCampaignData} className={`${classes.form_column} overflow-y-auto`}>
        <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
          <h2 className="text-lg mb-4 !text-black">Animal Name</h2>
          <span className="text-[--default-red]">*</span>
        </label>
        <input
          name="animal_name"
          value={campaignData.animal_name}
          onChange={handleChange}
          className="text-black rounded-md"
        />
        <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
          <h2 className="text-lg mb-4 !text-black">Campaign Title</h2>
          <span className="text-[--default-red]">*</span>
        </label>
        <input
          name="campaign_title"
          value={campaignData.campaign_title}
          onChange={handleChange}
          className="text-black"
        />
        <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
          <h2 className="text-lg mb-4 !text-black">Description</h2>
          <span className="text-[--default-red]">*</span>
        </label>
        <Editor
          defaultValue={campaignData.description}
          onTextChange={handleEditorChange}
        />
        <div className={classes.form_row}>
          <div className="flex flex-col grow mt-2">
            <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
              <h2 className="text-lg mb-4 !text-black">Donation Goal</h2>
              <span className="text-[--default-red]">*</span>
            </label>
            <input
              type="number"
              name="donation_goal"
              value={campaignData.donation_goal}
              onChange={handleChange}
              placeholder="Donation Goal Amount (Number)"
              className="text-black !max-w-[170px]"
            />
          </div>
          <div className="flex flex-col grow mt-0.5">
            <label className="text-left mt-2 ">
              <h2 className="text-lg mb-4 !text-black">Donation Box Title</h2>
            </label>
            <div className={`${classes.form_row} !-mt-2`}>
              <input
                name="donation_box_title"
                value={campaignData.donation_box_title}
                onChange={handleChange}
                placeholder="Donation Box Title (Max. 30 Chars)"
                maxLength="30"
                style={{ margin: "0" }}
                className="text-black max-h-[38px]"
              />
              <SharedTooltip
                id="my-tooltip"
                content="The heading right above the donation box"
              />
            </div>
          </div>
        </div>
        <label className="text-left mt-2">
          <h2 className="text-lg mb-2 !text-black">Video URLS</h2>
        </label>
        <input
          name="video_urls"
          value={campaignData.video_urls}
          onChange={handleChange}
          placeholder="Video URLs (Separate with comma)"
          className="text-black"
        />
        <div className={classes.form_column}>
          <div className={classes.form_row}>
            <div className="flex flex-col grow">
              <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
                <h2 className="text-lg mb-4 !text-black">Age</h2>
                <span className="text-[--default-red]">*</span>
              </label>
              <select
                name="age"
                value={
                  Object.keys(ageOptions).find(
                    (key) => ageOptions[key] === campaignData.age
                  ) || ""
                }
                onChange={handleChange}
                className="text-black"
              >
                <option value="" disabled>
                  Select Age
                </option>
                {Object.keys(ageOptions).map((age) => (
                  <option key={age} value={age}>
                    {age}
                  </option>
                ))}
              </select>
            </div>
            <div className="flex flex-col grow">
              <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
                <h2 className="text-lg mb-4 !text-black">Color</h2>
                <span className="text-[--default-red]">*</span>
              </label>
              <input
                name="color"
                value={campaignData.color}
                onChange={handleChange}
                placeholder="Color"
                className="text-black"
              />
            </div>
          </div>
          <div className={classes.form_row}>
            <div className="flex flex-col grow">
              <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
                <h2 className="text-lg mb-4 !text-black">Breed</h2>
                <span className="text-[--default-red]">*</span>
              </label>
              <input
                name="breed"
                value={campaignData.breed}
                onChange={handleChange}
                placeholder="Breed"
                className="text-black"
              />
            </div>
            <div className="flex flex-col grow">
              <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
                <h2 className="text-lg mb-4 !text-black">Gender</h2>
                <span className="text-[--default-red]">*</span>
              </label>
              <select
                name="gender"
                value={campaignData.gender}
                onChange={handleChange}
                className="text-black"
              >
                <option value="" selected disabled={true}>
                  Gender
                </option>
                <option value="female">Female</option>
                <option value="male">Male</option>
              </select>
            </div>
            <div className="flex flex-col grow">
              <label className="text-left mt-2 -mb-2 flex flex-row gap-1.5">
                <h2 className="text-lg mb-4 !text-black">Species</h2>
                <span className="text-[--default-red]">*</span>
              </label>
              <select
                name="species"
                value={campaignData.species}
                onChange={handleChange}
                className="text-black"
              >
                <option value="" selected disabled={true}>
                  Species
                </option>
                <option value="dog">Dog</option>
                <option value="cat">Cat</option>
                <option value="farm">Farm</option>
                <option value="exotic">Exotic</option>
                <option value="other">Other</option>
              </select>
            </div>
          </div>
        </div>
        <div className={classes.graphic_form_row}>
        <h2 className="text-lg mb-4 !text-black">Upload Images</h2>
        </div>
        <SortableList
          items={imagePreviews}
          onSortEnd={onSortEnd}
          axis="xy"
          helperClass={classes.sortable_helper}
        />

        <GeneralButton
          onClick={() => fileInputRef.current.click()}
          colour="orange"
          className="w-1/2 justify-center items-center flex m-auto"
          type="button"
        >
          Add Image
        </GeneralButton>
        <input
          onChange={handleImageChange}
          multiple
          ref={fileInputRef}
          name="images"
          type="file"
          hidden
        />

        <div className={classes.graphic_form_row}>
          <h2 htmlFor="graphic-image-upload" className="text-left text-lg !text-black mt-4">
            Upload Graphic Images
            {campaignData.graphic_images.length === 0
              ? ""
              : `(${campaignData.graphic_images.length})`}
          </h2>
          <SharedTooltip
            id="graphic-images-tooltip"
            content="These will initially be blurred out until a user clicks 'Show'"
          />
        </div>
        <GeneralButton
          onClick={() => graphicFileInputRef.current.click()}
          colour="orange"
          className="w-1/2 justify-center items-center flex m-auto"
          type="button"
        >
          Add Image
        </GeneralButton>
        <input
          onChange={handleGraphicImageChange}
          multiple
          ref={graphicFileInputRef}
          name="graphic_images"
          type="file"
          hidden
        />

        <div>
          <GeneralButton colour={"blue"} type="submit" disabled={loading}>
            {loading ? "Saving..." : "Save Campaign"}
          </GeneralButton>
        </div>
      </form>
      
      <GeneralButton colour={"orange"} type="preview" onClick={() => handleOpenPreview()}>
        Preview
      </GeneralButton>

      {!createError && (
          <p style={{ color: "green" }}>{campaignData.success}</p>
        )}
      {createError ? <p style={{ color: "red" }}>{createError}</p> : ""}

      {previewModalOpen && (
        <CampaignFeature
          isOpen={previewModalOpen}
          onClose={() => handleClosePreview()}
          campaignData={campaignData}
          imagePreviews={imagePreviews}
          graphicImagePreviews={graphicImagePreviews}
        />
      )}
      
      <QRCodeModal
        isOpen={qrModalOpen}
        onClose={() => setQrModalOpen(false)}
        campaignUrl={campaignUrl}
      />
    </>
  );
};

const CampaignModal = ({
  isOpen,
  onClose,
  nonprofitId,
  nonprofitName,
  setCampaigns,
  setQrModalOpen,
  nonprofitAddress,
}) => {
  const [campaignUrl, setCampaignUrl] = useState("");

  if (!isOpen) return null;

  const handleCampaignSuccess = (url) => {
    const { protocol, host } = window.location;
    setCampaignUrl(`${protocol}//${host}` + url);
    setQrModalOpen(true);
  };

  return (
    <div className={classes.campaign_modal_overlay}>
      <div className={classes.campaign_modal}>
        <button onClick={onClose} className={classes.close_button}>
          x
        </button>
        <h2 className={`${classes.form_title} font-bold`}>
          Create New Animal Campaign
        </h2>
        <CampaignForm
          nonprofitName={nonprofitName}
          nonprofitId={nonprofitId}
          nonprofitAddress={nonprofitAddress}
          onSuccess={handleCampaignSuccess}
          campaignUrl={campaignUrl}
          setCampaigns={setCampaigns}
        />
      </div>
    </div>
  );
};

export default CampaignModal;