import React, { useEffect, useState, useRef } from "react";
import Slider from "react-slick";
import { urlifyName } from "../shared/Utils/PageUrl";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHandHoldingHeart,
  faPiggyBank,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";
import { getAnnouncements } from "../../api/Announcements";
import useWindowDimensions from "../../hooks/useWindowDimensions";
import announcementCoverMobile from "../../assets/announcement2.jpg";
import { getPaginatedCampaigns } from "../../api/Campaigns";
import Arrows from "./CircleText/Arrows";
import CircleText from "./CircleText/CircleText";
import UsaMap from "./UsaMap/UsaMap";
import classes from "./Homepage.module.css";
import CampaignCard from "../Campaigns/CampaignCard/CampaignCard";
import desktopBackground from "../../assets/newsletter-bg.png";
import mobileBackground from "../../assets/newsletter-bg-mobile.png";
import { subscribeMarketingNewsletter } from "../../api/SubscribeNewsletter";
import { errorHandler } from "../shared/Utils/ErrorHandler";
import Spinner from "../shared/LoadingSpinner/Spinner";

// Creating logo list
function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex > 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}
const logos = require.context("../../assets/nonprofitLogos", true);
const logoList = shuffle(logos.keys().map((logo) => logos(logo)));

function HomePage() {
  const [announcements, setAnnouncements] = useState([]);
  const [campaignsSubset, setCampaignsSubset] = useState([]);
  const [email, setEmail] = useState(null);
  const { width } = useWindowDimensions();
  const sliderRef = useRef(null);
  const [hover, setHover] = useState(-1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  // Getting api responses
  useEffect(() => {
    const fetchAllData = () => {
      setIsLoading(true);

      getAnnouncements()
        .then((response) => {
          setAnnouncements(response.slice(0, 3));
        })
        .catch((error) => {
          console.error("Could not retrieve announcements", error);
        });
      
      getPaginatedCampaigns(1, "", "", "", "")
        .then((response) => {
          setCampaignsSubset(response.results.slice(0, 4));
        })
        .catch((error) => {
          console.error("Could not retrieve campaigns", error);
        }).finally(() => {
          setTimeout(() => {
            setIsLoading(false);
          }, 300);
        });
    };

    fetchAllData();
  }, []);
  // end getting api responses

  function handleGetStarted(data) {
    if (data) {
      subscribeMarketingNewsletter({ email: data, active: false })
        .then(() => {
          setEmail("");
          alert("Thank you for subscribing to our newsletter!");
        })
        .catch((error) => {
          console.error(error);
          const err = errorHandler(error);
          alert("Error while subscribing to our newsletter: " + err);
        });
    } else {
      alert("Please enter an email address.");
    }
  }

  let slideshowSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 1000,
    autoplaySpeed: 10000, // 10 secs
    pauseOnHover: false,
  };

  let logoCarouselSettings = {
    dots: false,
    infinite: true,
    slidesToShow: 8,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 0,
    pauseOnHover: false,
    cssEase: "linear",
  };

  let logoCarouselSettingsMedium = {
    ...logoCarouselSettings,
    slidesToShow: 4,
  };
  let logoCarouselSettingsSmall = {
    ...logoCarouselSettings,
    slidesToShow: 3,
    speed: 2000,
  };

  const handleMouseEnter = (id) => {
    if (sliderRef.current) {
      sliderRef.current.slickPause();
    }
    setHover(id);
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay();
    }
    setHover(-1);
  };

  const handleClick = (campaign) => {
    navigate(
      `/campaigns/${urlifyName(campaign.nonprofit_name)}/${urlifyName(
        campaign.animal_name
      )}/${campaign.id}`
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-row ml-auto mt-[35vh] w-full h-full justify-center">
        <Spinner />
      </div>
    );
  }

  return (
    <div className={`${classes.homepage} main-div !bg-grey-home`}>
      <div className={classes.slideshow_container}>
        {announcements.length > 0 ? (
          <Slider ref={sliderRef} {...slideshowSettings}>
            <div className={`relative w-full h-[80vh]`}>
              <img
                src={width >= 768 ? desktopBackground : mobileBackground}
                alt="Be a star and donate to an animal in need"
                className={`w-full h-full object-cover`}
              />
              <div
                className={`${classes.custom_slide_overlay} absolute top-0 left-0 w-100 h-100 flex flex-col justify-between items-start text-white p-8`}
              >
                <h2
                  className={`${classes.custom_slide_heading} text-white text-[2.5rem] mb-24 mt-[4.5rem]`}
                >
                  Be a star and donate to an animal in need
                </h2>
                <div
                  className={`${classes.custom_slide_buttons} flex flex-col ml-20 mb-8 gap-4 font-extrabold w-64`}
                >
                  <button
                    className={`${classes.custom_slide_button} flex flex-row justify-center items-center gap-3 bg-white border-none rounded-3xl text-gray-900 font-medium cursor-pointer py-3 px-6 hover:bg-blue-dark1`}
                    onClick={() => (window.location.href = "/campaigns")}
                  >
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.6627 9.99995L0.387695 5.72495L1.45645 4.6562L4.6627 7.86245L11.5439 0.981201L12.6127 2.04995L4.6627 9.99995Z"
                        className="fill-gray-900"
                      />
                    </svg>
                    Find Animals to Help
                  </button>
                  <button
                    className={`${classes.custom_slide_button} flex flex-row justify-center items-center gap-3 bg-blue border-none rounded-3xl text-gray-900 font-medium cursor-pointer py-3 px-6 hover:bg-blue-dark1`}
                    onClick={() => (window.location.href = "/petstar")}
                  >
                    <svg
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.6627 9.99995L0.387695 5.72495L1.45645 4.6562L4.6627 7.86245L11.5439 0.981201L12.6127 2.04995L4.6627 9.99995Z"
                        className="fill-gray-900"
                      />
                    </svg>
                    Join PetStar
                  </button>
                </div>

                <div
                  className={`${classes.custom_slide_columns} flex flex-row justify-center items-end gap-8 ml-16 w-full`}
                >
                  <div
                    className={`${classes.custom_slide_left_column} flex flex-col w-1/2`}
                  >
                    <h3
                      className={`${classes.custom_slide_subheading} flex flex-row gap-4 font-extrabold m-4 text-2xl !leading-7`}
                    >
                      <svg
                        width="24"
                        height="25"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 18.27L18.18 22L16.54 14.97L22 10.24L14.81 9.63L12 3L9.19 9.63L2 10.24L7.46 14.97L5.82 22L12 18.27Z"
                          className="fill-blue-400"
                        />
                      </svg>
                      Are you a Shelter/Non Profit?
                    </h3>
                    <button
                      className={`${classes.custom_slide_join_button} bg-blue font-semibold border-none text-white w-44 h-6 cursor-pointer rounded-3xl hover:bg-blue-dark1 ml-12`}
                      onClick={() => (window.location.href = "/signup")}
                    >
                      Join Us!
                    </button>
                  </div>
                  <div
                    className={`${classes.custom_slide_right_column} flex flex-col grow w-11/12`}
                  >
                    <label
                      htmlFor="email"
                      className={`${classes.custom_slide_label} text-sm text-white font-semibold mb-2 pl-2`}
                    >
                      Stay Updated on the latest animal news!
                    </label>
                    <div
                      className={`${classes.custom_slide_input_container} flex flex-row justify-start items-end gap-4`}
                    >
                      <input
                        type="email"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                        className={`${classes.custom_slide_input} w-80 h-9 text-black border-none rounded-3xl pl-8`}
                      />
                      <button
                        className={`${classes.custom_slide_get_started} text-sm w-36 h-9 font-semibold text-white border-none bg-orange cursor-pointer rounded-3xl hover:bg-orange-dark1`}
                        onClick={() => handleGetStarted(email)}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {announcements.map((announcement) => (
              <div className={classes.announcement_slide} key={announcement.id}>
                <img
                  className={classes.announcement_slide_content}
                  src={announcement.image}
                  alt=""
                />
                <div className={classes.announcement_slide_content}>
                  <h1>{announcement.title}</h1>
                  <h3>{announcement.sub_title}</h3>
                  {announcement.button_primary_url &&
                    announcement.button_primary_text && (
                      <a
                        className={`${classes.homepage_button} border hover:bg-orange-dark1 rounded-3xl ${classes.announcement_button}`}
                        href={announcement.button_primary_url}
                      >
                        {announcement.button_primary_text}
                      </a>
                    )}
                  {announcement.button_secondary_url &&
                    announcement.button_secondary_text && (
                      <a
                        className={`${classes.homepage_button} ${classes.announcement_button} border hover:bg-blue-dark1 rounded-3xl ${classes.announcement_button_alt}`}
                        href={announcement.button_secondary_url}
                      >
                        {announcement.button_secondary_text}
                      </a>
                    )}
                </div>
              </div>
            ))}
          </Slider>
        ) : announcements.length === 0 ? (
          <Slider ref={sliderRef} {...slideshowSettings}>
            <div className={classes.custom_slide}>
              <img
                src={width >= 768 ? desktopBackground : mobileBackground}
                alt="Be a star and donate to an animal in need"
                className={classes.custom_slide_image}
              />
              <div className={classes.custom_slide_overlay}>
                <h2 className={classes.custom_slide_heading}>
                  Be a Star and Donate to an Animal
                </h2>
                <div className={classes.custom_slide_buttons}>
                  <button
                    className={`${classes.custom_slide_button} hover:bg-blue-dark1`}
                    onClick={() => (window.location.href = "/campaigns")}
                  >
                    Find Animals to Help
                  </button>
                  <button
                    className={`${classes.custom_slide_button} hover:bg-blue-dark1`}
                    onClick={() => (window.location.href = "/petstar")}
                  >
                    Join PetStar
                  </button>
                </div>
                <div className={classes.custom_slide_columns}>
                  <div className={classes.custom_slide_left_column}>
                    <h3 className={classes.custom_slide_subheading}>
                      Are you a Shelter/Non Profit?
                    </h3>
                    <button
                      className={`${classes.custom_slide_join_button} hover:bg-orange-dark1`}
                      onClick={() => (window.location.href = "/signup")}
                    >
                      Join Us!
                    </button>
                  </div>
                  <div className={classes.custom_slide_right_column}>
                    <label
                      htmlFor="email"
                      className={classes.custom_slide_label}
                    >
                      Stay Updated on the latest animal news!
                    </label>
                    <div className={classes.custom_slide_input_container}>
                      <input
                        type="email"
                        value={email}
                        name="email"
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        required
                        className={classes.custom_slide_input}
                      />
                      <button
                        className={`${classes.custom_slide_get_started} hover:bg-orange-dark1`}
                        onClick={() => handleGetStarted(email)}
                      >
                        Get Started
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Slider>
        ) : (
          ""
        )}
        {width >= 768 ? (
          <section className="bg-grey-home">
            <h2 className={classes.announcements_cover_mobile_header}>
              We help non-profits fundraise money
            </h2>
            <div className={classes.announcements_cover}>
              <CircleText
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hover={hover}
              />
              <Arrows />
              <img
                style={{ gridArea: "3 / 3 / 6 / 6" }}
                className={classes.announcements_cover_image}
                src={announcementCoverMobile}
                alt="Non-Profits Turn to Us for Critical Support and Action. We organize a campaign to collect money. We advocate the pet's rights. We find corporate partners. We send money to the non-profit. Transforming Lives: Ensuring Every Pet Receives the Care They Deserve."
              />
            </div>
          </section>
        ) : (
          <section className="bg-grey-home">
            <h2 className={classes.announcements_cover_mobile_header}>
              We help non-profits fundraise money
            </h2>
            <div className={classes.announcements_cover}>
              <CircleText
                handleMouseEnter={handleMouseEnter}
                handleMouseLeave={handleMouseLeave}
                hover={hover}
              />
              <Arrows />
              <img
                style={{ gridArea: "3 / 3 / 6 / 6" }}
                className={classes.announcements_cover_mobile_image}
                src={announcementCoverMobile}
                alt="Non-Profits Turn to Us for Critical Support and Action. We organize a campaign to collect money. We advocate the pet's rights. We find corporate partners. We send money to the non-profit. Transforming Lives: Ensuring Every Pet Receives the Care They Deserve."
              />
            </div>
          </section>
        )}
      </div>

      <div
        className={`${classes.homepage_section} !sm:flex-col !flex-row !pb-12 !pt-12`}
      >
        <div
          className={`${classes.homepage_section_header} !w-1/2 flex-col justify-center items-start gap-8 pr-12`}
        >
          <h1>Who Are We?</h1>
          <p className="!text-black sm:flex sm:align-center sm:justify-center">
            Sponsor a Pet is a fundraising platform, dedicated to providing
            Animal Non-Profits a platform to share their stories and help raise
            necessary resources to save the lives of animals. We do this by
            working together with passionate local organizations to amplify our
            impact in communities as we come together as one.
          </p>
          <Link
            className={`${classes.homepage_button} hover:bg-orange-dark1 border rounded-3xl !ml-0 `}
            to={`/about-us`}
          >
            Learn More
          </Link>
        </div>
        <div className="lg:pl-12 sm:pl-0">
          <iframe
            width="560"
            height="315"
            src="https://sap-app-staging.s3.us-west-2.amazonaws.com/internal/who_we_are_new.mp4"
            title="Who We Are"
            frameBorder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
            loading="lazy"
          ></iframe>
        </div>
      </div>

      <div className={`${classes.homepage_section_dark} !bg-blue !my-0`}>
        <h1 className="text-center text-white !text-3xl mt-4 sm:px-3">
          Reasons to give to Sponsor a Pet
        </h1>
        <div className={classes.homepage_reasons_container}>
          <div className={classes.homepage_reasons_card}>
            <FontAwesomeIcon icon={faPiggyBank} />
            <h3 className="text-white">Tax Deductible Donations</h3>
            <p>
              All Monetary Donations to our non-profit partners are tax
              deductible.
            </p>
          </div>
          <div className={classes.homepage_reasons_card}>
            <FontAwesomeIcon icon={faHandHoldingHeart} />
            <h3 className="text-white">Verified 501(c)(3) Organizations</h3>
            <p>
              Our verified non-profits partners pay nothing for our services.
            </p>
          </div>
          <div className={classes.homepage_reasons_card}>
            <FontAwesomeIcon icon={faPeopleGroup} />
            <h3 className="text-white">Join Our Community</h3>
            <p>
              Working together amplifies our ability to accomplish our goals of
              providing every animal love and a home.
            </p>
          </div>
        </div>
      </div>

      {campaignsSubset.length > 0 ? (
        <div className={`${classes.homepage_section} !py-12 !px-0 !m-0`}>
          <h1 className="text-center text-orange mb-12 !text-3xl">
            Our Animal Campaigns
          </h1>
          <div
            className={`${classes.homepage_campaigns_container} ${classes.homepage_campaigns_container_mobile}`}
          >
            {campaignsSubset.map((campaign) => {
              return (
                <CampaignCard
                  key={campaign.id}
                  campaign={campaign}
                  handleClick={handleClick}
                />
              );
            })}
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        className={`${classes.homepage_section} flex text-center text-3xl !m-0 !p-0  `}
      >
        <h2 className="text-orange font-bold py-8">Our Partners</h2>
        <div className={`${classes.hompage_section} p-0 !w-full`}>
          {width >= 1200 ? (
            <Slider {...logoCarouselSettings}>
              {
                // Rendering logos
                logoList.map((logoUrl) => (
                  <div className={classes.logo_slide} key={logoUrl}>
                    <img src={logoUrl} alt="" />
                  </div>
                ))
              }
            </Slider>
          ) : width >= 768 ? (
            <Slider {...logoCarouselSettingsMedium}>
              {
                // Rendering logos
                logoList.map((logoUrl) => (
                  <div className={classes.logo_slide} key={logoUrl}>
                    <img src={logoUrl} alt="" />
                  </div>
                ))
              }
            </Slider>
          ) : width >= 0 ? (
            <Slider {...logoCarouselSettingsSmall}>
              {
                // Rendering logos
                logoList.map((logoUrl) => (
                  <div className={classes.logo_slide} key={logoUrl}>
                    <img src={logoUrl} alt="" />
                  </div>
                ))
              }
            </Slider>
          ) : null}
        </div>
        <h2 className="text-orange font-bold py-8">
          Find A Nonprofit Near You
        </h2>
        <UsaMap />
      </div>

      <div
        className={`${classes.homepage_section_dark} !p-0 !flex-row !h-[749px] !bg-grey-home`}
      >
        <div
          className={`${classes.homepage_section_dark_header} !m-0 w-[35.5%] text-center pr-16 !bg-red-400`}
        >
          <h1 className="!text-6xl !mt-16 !leading-relaxed">
            Make a Difference Today
          </h1>
        </div>
        <div className={` w-full p-0 lg:w-2/3 !m-0`}>
          <div className="relative w-full h-full">
            <img
              src="https://sap-app-prod.s3.us-west-2.amazonaws.com/web_images/home-page-donate.png"
              alt=""
              className="h-full w-full absolute inset-0 object-cover"
            />
            <div className="absolute inset-0 flex flex-col justify-center items-end text-right p-4 ml-20 mr-10">
              <p className="text-white text-xl">
                Sponsor a Pet is a fundraising platform, dedicated to providing
                Animal Non-Profits a platform to share their stories and help
                raise necessary resources to save the lives of animals. We do
                this by working together with passionate local organizations to
                amplify our impact in communities as we come together as one.
              </p>
              <Link
                className={`p-0 w-32 h-16 text-base text-white inline-flex items-center justify-center font-bold no-underline bg-orange hover:bg-orange-dark1 border border-transparent rounded-3xl mt-8 max-[450px]:!w-40`}
                to="/petstar"
              >
                Donate
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
