import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const petstarSubscribe = async (data) => {
	try {
		const config = {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
			}
		}

		const response = await axios.post(`${API_URL}/petstar/subscribe/`, data, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			console.error("Petstar subscribe error:", error.response);
			throw error;
		}
	}
};

export const petstarCustomSubscribe = async (data) => {
	try {
		const config = {
			headers: {
				'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
                'Content-Type': 'application/json'
			}
		}
		const response = await axios.post(`${API_URL}/petstar/custom/subscribe/`, data, config);
		return response.data;
	} catch (error) {
		if (error.response && error.response.status === 500) {
      		console.error("500 Internal Server Error");
			throw error;
    	} else {
			console.error("Petstar subscribe error:", error.response);
			throw error;
		}
	}
};

export const petstarSubscriptionUpdate = async (userId, data) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
      },
    };

    const response = await axios.post(
      `${API_URL}/petstar/${userId}/update/`,
      data,
      config
    );
    return response.data;
  } catch (error) {
    if (error.response && error.response.status === 500) {
      console.error("500 Internal Server Error");
      throw error;
    } else {
      console.error("Error is:", error);
      throw error;
    }
  }
};
