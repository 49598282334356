import { useEffect, useState } from "react";
import { uploadDonors } from "../../../api/UploadDonors";
import { getDonors } from "../../../api/GetDonors";
import { useNavigate } from "react-router-dom";
import DonorTable from "./DonorTable/DonorTable";
import GeneralButton from "../../shared/Buttons/GeneralButton";
import { lineBreaks } from "../../shared/Utils/LineBreaks";
import { errorHandler } from "../../shared/Utils/ErrorHandler";
import classes from "./DonorList.module.css";
import dashboardClasses from "../Dashboard.module.css";

function DonorList() {
  const [localUserData, setLocalUserData] = useState(null);
  const [donorData, setDonorData] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [feErrorMessage, setFEErrorMessage] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [donationSearchInput, setDonationSearchInput] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [ifSearch, setIfSearch] = useState(false);
  const [filter, setFilter] = useState("no-filter"); 
  const navigate = useNavigate();
  const S3_URL =
    "https://sap-app-staging.s3.us-west-2.amazonaws.com/resources/new_donor_list.csv";

  const downloadFile = () => {
    window.location.href = S3_URL;
  };

  const getDonorList = async () => {
    try {
      const response = await getDonors(localUserData.nonprofit);
      if (response.length > 0) {
        setDonorData(response);
      }
    } catch (error) {
      console.error("Error getting Donors: ", error);
    }
  };

  useEffect(() => {
    // Retrieve user data from localStorage
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      try {
        const userData = JSON.parse(userDataString);
        setLocalUserData(userData);
      } catch (error) {
        console.error("Failed to parse user data: ", error);
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");
        navigate("/");
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (localUserData) {
      getDonorList();
    }
  }, [localUserData]);

  const uploadDonorList = async (event) => {
    setFEErrorMessage("");
    setErrorMsg("");
    setSuccessMessage("");
    const csvfile = event.target.files[0];
    if (csvfile) {
      if (csvfile.type !== "text/csv") {
        setFEErrorMessage("Please upload a CSV file.");
        return;
      }
      const formData = new FormData();
      formData.append("file", csvfile);
      try {
        if (localUserData) {
          const response = await uploadDonors(
            formData,
            localUserData.nonprofit
          );
          setSuccessMessage(
            "Upload successful. Please refresh the page to view Donors."
          );
        } else {
          navigate("/signup");
        }
      } catch (error) {
        console.error("Error uploading file: ", error.response);
        const errMsg = errorHandler(error);
        setErrorMsg(errMsg);
      }
    }
  };

  function convertToCSV(arr) {
    // Extract column names excluding the 'address' field
    const columnDelimiter = ",";
    const lineDelimiter = "\n";
    const keys = Object.keys(arr[0]).filter((key) => key !== "address");

    // Join column names to make the CSV header
    let csv = keys.join(columnDelimiter) + lineDelimiter;

    arr.forEach((obj) => {
      csv += keys.map((key) => obj[key]).join(columnDelimiter) + lineDelimiter;
    });

    return csv;
  }

  const downloadDonorsCSV = () => {
    if (donorData && donorData.length > 0) {
      const csvString = convertToCSV(donorData);
      const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" });

      const link = document.createElement("a");
      if (link.download !== undefined) {
        // feature detection
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", "donors.csv");
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  };

 const handleDonationSearchChange = (e) => {
   e.preventDefault();
   setDonationSearchInput(e.target.value);

   setIfSearch(true);
   if (e.target.value === "") {
     setIfSearch(false);
   }

   const searchResults = searchDonations();
   setSearchResults(searchResults);
 };

 const searchDonations = () => {
   const filteredData = getFilteredData();
   return filteredData.filter((donation) =>
     donationSearchInput !== ""
       ? donation.email
           .toLowerCase()
           .includes(donationSearchInput.toLowerCase())
       : true
   );
 };

const handleFilterChange = (e) => {
  setFilter(e.target.value);
  setDonationSearchInput("");
  setIfSearch(false);
  const filteredData = getFilteredData();
  setSearchResults(filteredData);
};

 const getFilteredData = () => {
   let filteredData = donorData;

   if (filter === "donations") {
     filteredData = donorData.filter(
       (donation) => donation.source === "donation"
     );
   } else if (filter === "uploaded") {
     filteredData = donorData.filter(
       (donation) => donation.source === "upload"
     );
   } else if (filter === "first_name") {
     filteredData = [...donorData].sort((a, b) =>
       a.first_name.localeCompare(b.first_name)
     );
   } else if (filter === "last_name") {
     filteredData = [...donorData].sort((a, b) =>
       a.last_name.localeCompare(b.last_name)
     );
   } else if (filter === "email") {
     filteredData = [...donorData].sort((a, b) =>
       a.email.localeCompare(b.email)
     );
   }

   return filteredData;
 };


  return (
    <>
      <div
        className={`${dashboardClasses.dashboard_content} ${classes.donor_dashboard_block} w-full ml-14 pt-12`}
      >
        <div className={`${classes.header_section} flex flex-col gap-5 pb-4`}>
          <h2 className="text-orange !text-3xl font-bold text-center">
            Donor List
          </h2>

          {feErrorMessage && (
            <p className="text-[var(--default-red)]">{feErrorMessage}</p>
          )}

          {successMessage && <p className="text-green-600">{successMessage}</p>}

          {errorMsg && (
            <p className="text-[var(--default-red)]">{lineBreaks(errorMsg)}</p>
          )}

          {localUserData &&
            (localUserData.np_access_level === "admin" ||
              localUserData.np_access_level === "editor") && (
              <div className={`flex justify-around items-center gap-4 grow`}>
                <GeneralButton
                  colour="blue"
                  className={`${classes.buttons} flex font-semibold justify-center items-center text-center cursor-pointer p-2 text-base text-white border-none !rounded-3xl h-8`}
                  onClick={downloadFile}
                >
                  Download Donor List CSV Template
                </GeneralButton>

                <label
                  className={`${classes.buttons} flex justify-center font-semibold hover:shadow-lg items-center text-center cursor-pointer p-2 text-base text-white border-none bg-blue !rounded-3xl h-8`}
                >
                  Upload Donor List
                  <input
                    type="file"
                    onChange={uploadDonorList}
                    className={`hidden`}
                  />
                </label>
              </div>
            )}
        </div>

        <div className={`flex items-center relative w-full`}>
          <svg
            className={`absolute top-2 left-2`}
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.3496 13.3559C13.2563 13.4478 13.1306 13.4994 12.9996 13.4996C12.8668 13.499 12.7393 13.4476 12.6434 13.3559L9.94336 10.6496C8.80622 11.6047 7.34425 12.084 5.86236 11.9874C4.38046 11.8908 2.99306 11.2259 1.98951 10.1313C0.985965 9.03661 0.443754 7.59681 0.475967 6.11212C0.508179 4.62743 1.11233 3.21249 2.16241 2.16241C3.21249 1.11233 4.62743 0.508179 6.11212 0.475967C7.59681 0.443754 9.03661 0.985965 10.1313 1.98951C11.2259 2.99306 11.8908 4.38046 11.9874 5.86236C12.084 7.34426 11.6047 8.80623 10.6496 9.94336L13.3496 12.6434C13.3969 12.6899 13.4344 12.7453 13.46 12.8065C13.4856 12.8677 13.4988 12.9333 13.4988 12.9996C13.4988 13.0659 13.4856 13.1316 13.46 13.1927C13.4344 13.2539 13.3969 13.3093 13.3496 13.3559ZM6.24961 10.9996C7.18907 10.9996 8.10743 10.721 8.88857 10.1991C9.6697 9.67715 10.2785 8.9353 10.638 8.06736C10.9976 7.19941 11.0916 6.24434 10.9083 5.32293C10.7251 4.40152 10.2727 3.55515 9.60837 2.89085C8.94407 2.22655 8.0977 1.77416 7.17629 1.59088C6.25488 1.4076 5.29981 1.50166 4.43186 1.86118C3.56391 2.2207 2.82206 2.82952 2.30013 3.61065C1.77819 4.39178 1.49961 5.31015 1.49961 6.24961C1.50126 7.50888 2.00224 8.7161 2.89268 9.60654C3.78312 10.497 4.99034 10.998 6.24961 10.9996Z"
              fill="var(--grey-icon)"
              fillOpacity="0.5"
            />
          </svg>
          <div className="flex flex-col md:flex-row items-center w-full">
            <input
              type="text"
              placeholder="Search Donation by Donor Email"
              value={donationSearchInput}
              onChange={handleDonationSearchChange}
              className={`p-2 pl-8 border text-gray-600 border-gray-300 rounded-lg mb-4 w-full h-7 `}
            />
            <select
              className="w-auto ml-2 pl-1 border text-gray-600 border-gray-300 rounded-lg mb-4 h-7 min-w-max"
              value={filter}
              onChange={handleFilterChange}
            >
              <option value="no-filter">No Filter</option>
              <option value="donations">Donations on Sponsor A Pet</option>
              <option value="uploaded">Uploaded Donations from CSV</option>
              <option value="first_name">Sort by First Name</option>
              <option value="last_name">Sort by Last Name</option>
              <option value="email">Sort by Email</option>
            </select>
          </div>
        </div>

        {donorData ? (
          ifSearch ? (
            <>
              <DonorTable
                nonprofitId={localUserData.nonprofit}
                data={searchResults}
              />
              <div
                className={`${classes.donor_dashboard_block} flex flex-col justify-center items-center gap-5 p-4`}
              >
                <GeneralButton
                  colour="blue"
                  className={`flex justify-center items-center font-semibold text-center cursor-pointer p-2 text-base text-white border-none !rounded-3xl no-underline h-8 w-1/3`}
                  onClick={downloadDonorsCSV}
                >
                  Download Donor Data as CSV
                </GeneralButton>
              </div>
            </>
          ) : (
            <>
              <DonorTable
                nonprofitId={localUserData.nonprofit}
                data={getFilteredData()}
              />
              <div
                className={`${classes.donor_dashboard_block} flex flex-col justify-center items-center gap-5 p-4`}
              >
                <GeneralButton
                  colour="blue"
                  className={`${classes.button_download_csv} flex justify-center items-center text-center cursor-pointer p-2 text-base text-white border-none !rounded-3xl no-underline h-8 bg-blue w-1/3`}
                  onClick={downloadDonorsCSV}
                >
                  Download Donor Data as CSV
                </GeneralButton>
              </div>
            </>
          )
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default DonorList;
