import { parseDescription } from "./ParseDescription";

export const extractMissionStatement = (stmt, returnArray) => {
    // Extracts the mission statement from a <p> tag (format saved by Quill)
    // if returnArray is true, returns an array of the mission statement 
    // if returnArray is false, returns the mission statement as a string without the <p> tag 

    // Splits NP mission statement into an array of sentences
    const getStatementArray = (txt) => {
        const sentences = txt.match(/[^.!?]+[.!?]*[\s]*/g) || [];
        // If the match returns an empty array, check if the text is non-empty and return it as a single element array
        return sentences.length === 0 && txt.trim() ? [txt.trim()] : sentences;
    };

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = stmt;
    const text = tempDiv.textContent || tempDiv.innerText || "";
    const statement = parseDescription(text);
    const sentences = getStatementArray(statement);

    // If the statement has only one sentence and returnArray is true, return it as a single-element array
    if (returnArray && sentences.length === 1) {
        return [sentences[0].trim()];
    }

    return returnArray ? sentences : statement;
};
