import React, { useState, useEffect , useRef} from "react";
import { customerPortal, createPetstarSubscription } from "../../../api/PetstarSuccess.js";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userDataActions } from "../../../store";
import { dashboard } from "../../../api/Dashboard.js";
import { useLocation } from 'react-router-dom';
import { getReferrals, getReferral } from "../../../api/Referrals.js";
import Spinner from "../LoadingSpinner/Spinner.js";

const PetstarSuccess = () => {
  const userData = useSelector((state) => state.userData.userData)
  const dispatch = useDispatch();
  const [localUserData, setLocalUserData] = useState(null);
  const [referralDonationCreated, setReferralDonationCreated] = useState(false);
  const [ifAReferral, setIfAReferral] = useState(false);

  // Persists across re-renders w/o causing the component to re-render
  const hasCreatedPortal = useRef(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const priceId = queryParams.keys().next().value;
  const affiliate_id = queryParams.get("id")

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const userDataString = localStorage.getItem("userData");

        if (userDataString) {
          const userDataParsed = JSON.parse(userDataString);
          const response = await dashboard(userDataParsed.id);
          dispatch(userDataActions.updateUserData(JSON.stringify(response)));
          setLocalUserData(response);
          localStorage.setItem("userData", JSON.stringify(response));
        }
      } catch (error) {
        console.error(
          "Failed to parse user data or fetch user profile: ",
          error
        );
        // Clear the localStorage items if they are corrupted or on error
        localStorage.removeItem("userData");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("accessToken");

        if (Object.keys(userData).length !== 0) {
          dispatch(userDataActions.removeUserData());
        }
      }
    };
      fetchUserData();
  }, [dispatch, userData]);

  useEffect(() => {
    const createCustomerPortal = async () => {
      if (localUserData && localUserData.stripe_customer_id && !hasCreatedPortal.current) {
        // Check if localUserData and localUserData.stripe_customer_id are defined

        hasCreatedPortal.current = true;
        const resp = await customerPortal(
          localUserData.stripe_customer_id,
          "subscribe",
          priceId
        );
      }
    };

    if (localUserData) {
      // Only attempt to create portal if localUserData is defined
      createCustomerPortal();
    }
  }, [localUserData]);

  // useEffect to create referral donation ONLY one time
  useEffect(() => {
    const getAllReferrals = async () => {
      try {
        // Checks if the donation is associated with an affiliate user
        if (affiliate_id) {
          setIfAReferral(true);
        }
        
        if (localUserData && !referralDonationCreated && ifAReferral) {
          const createReferralDonation = async () => {
            try {
              const response = await createPetstarSubscription(localUserData.id, priceId);
            } catch (error) {
              console.error("Could not create referral donation", error);
            }
          };
    
          createReferralDonation();
          setReferralDonationCreated(true);
        }
      } catch (error) {
        console.error("Could not create referral donation", error);
      }
    }

    if (localUserData && !referralDonationCreated) {
      getAllReferrals();
    }
  }, [localUserData])

  return (
    <div>
      <div className="flex justify-center content-center h-[100vh] text-center">
        <h3 className="mt-48">
          Thank you for becoming a PetStar! You can manage your subscription from your Profile.
        </h3>
      </div>
    </div>
  );
};

export default PetstarSuccess;
